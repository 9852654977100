<template>
  <div>
    <O360Tour />
    <div>
      <Topheader></Topheader>
      <Bottomheader></Bottomheader>
    </div>
    <!-- <div id="body-back" :class="userRegistered ? '' : 'noRolesBody'"></div> -->
    <div id="body-back"></div>
    <div class="Dash-container">
      <div class="coreContainer">
        <div v-if="userRegistered" class="registered-user-view">
          <div id="welcome">
            <div id="welcome-text">
              <p>Welcome!</p>
            </div>
          </div>
          <div id="tourContainer"></div>
          <div class="cards-container-box">
            <div id="card-position">
              <Card
                v-for="(contentObj, index) in currentTargets"
                :key="index"
                :targetComponunt="contentObj.urlDisplayName"
                :entryPoint="contentObj.entryPoint"
                :index="index"
                :id="contentObj.name"
                :ref="contentObj.name"
              ></Card>
            </div>
          </div>
        </div>
        <NoticeScreen
          v-else
          title="Welcome!"
          description="Your request for an account has been submitted and will need to be approved.
              Upon approval you will receive an email notification with details of the type of access you have been granted.
              <br><br>If you do not receive an email within 24 hours please contact support."
          :overlay="false"
        ></NoticeScreen>
      </div>

      <O360Footer></O360Footer>
    </div>
  </div>
</template>

<script>
import { B2CManager } from "@/Lib/B2CManager.js";
import configurationManager from "@/Lib/ConfigurationManager.js";
import Card from "@/components/Card.vue";
import Axios from "axios";
export default {
  mixins: [B2CManager],
  components: {
    Card
  },
  props: {
    initialopenapp: String,
    apptabs: Array
  },
  data: function () {
    return {
      /*  currentTargets: [
        { name: "JointUse", cardContent: "JointUseContent" },
        { name: "ProjectDashboard", cardContent: "OsmoseUniversityContent" },
        { name: "OsmoseUniversity", cardContent: "ProjectDashboardContent" },
        { name: "KnowledgeBase", cardContent: "KnowledgeBaseContent" }
      ], */
      currentComponentContent: "",
      contentObj: null
    };
  },
  computed: {
    currentTargets() {
      //return this.$store.getters.appRegistry;
      if (this.$store.getters.userApplications) {
        return this.$store.getters.userApplications
          .filter(display => display.cardDisplay)
          .slice()
          .sort((a, b) => a.cardSerialNumber.localeCompare(b.cardSerialNumber));
      }
      return this.$store.getters.userApplications;
    },
    userRegistered() {
      return (
        (this.$store.getters.userApplications == null ||
          this.$store.getters.userApplications.length > 0) &&
        (this.userCustomersLength == null || this.userCustomersLength > 0)
      );
    },
    userCustomersLength() {
      return this.$store.getters.userCustomersLength;
    }
  },
  beforeRouteLeave(to, from, next) {
    document.body.style["pointer-events"] = "auto";
    document.body.style["overflow"] = "visible";
    next();
  }
};
</script>

<style scoped>
.Dash-container {
  min-height: 300px;
  min-width: 550px;
  height: 100%;
  position: relative;
  background-color: rgba(59, 59, 59, 0.4);
}

.Dash-container::before {
  content: "";
  position: fixed;
  top: -140px;
  left: -120px;
  width: calc(100% + 120px);
  min-height: calc(100% + 140px);
  height: auto;
  z-index: -1;
  background: var(--wood-poles-distribution2-url) no-repeat center center;
  background-size: cover;
  overflow: hidden;
}
.coreContainer {
  width: 100%;
  /* min-height: 710px; */
  /* height: calc(100vh - 146px); */
  min-height: calc(100vh - 146px) !important;
  height: auto;
  margin: auto;
}
.registered-user-view {
  height: 100%;
  width: 100%;
}
.cards-container-box {
  display: grid;
  grid-template-columns: auto 1350px auto;
  justify-items: center;
}

#card-position {
  grid-column-start: 2;
  grid-column-end: 3;
  float: left;
}
#body-back {
  height: 109px;
}
/* .noRolesBody {
  height: 13px !important;
} */
#welcome-text {
  text-align: center;
  color: #ffffff;
  font-family: "Segoe UI";
  font-size: 30px;
  font-weight: bold;
  padding-top: 26px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1367px) {
  .cards-container-box {
    display: grid;
    grid-template-columns: auto 900px auto;
  }
}

@media only screen and (max-width: 991.98px) {
  .cards-container-box {
    display: grid;
    grid-template-columns: auto 450px auto;
  }
}
</style>
