<template>
  <div class="juNotification">
    <div @click="toggleDropdown = !toggleDropdown">
      <span class="posRelative">
        <span class="cartCount" v-if="getNotifications.length > 0">!</span>
        <i class="fas fa-bell"></i>
      </span>
    </div>
    <div class="cstDropdown" v-show="toggleDropdown">
      <div class="clearfix notiHeader">
        <div class="float-start">
          Notifications &nbsp;<span>{{ notificationCount }}</span>
        </div>
        <div class="float-end">
          <a href="javascript:void(0);" @click="clearAllNotification()">
            Clear all
          </a>
        </div>
      </div>
      <div class="juhgtNotification">
        <div
          class="position-relative notiBody"
          v-for="notification in getNotifications"
          :key="JSON.stringify(notification)"
        >
          <div class="w-100">
            <template v-if="notification.notificationModule == 'jointuse'">
              <div class="notisubHeader">
                <strong
                  >{{ notification.projectName }}
                  &nbsp; : &nbsp;Joint Use Apply
                </strong>
              </div>
              <div
                class="notiList"
                v-for="file in notification.files"
                :key="file.id"
              >
                <div
                  :class="
                    file.files.mainStatus.toLowerCase() == 'failed'
                      ? 'notiFailure'
                      : ''
                  "
                  :title="file.files.name"
                  class="ps-2 pe-2"
                  @click="handleNotificationClick(file)"
                >
                  <i
                    class="fas fa-check-circle"
                    v-if="
                      file.files.mainStatus.toLowerCase() == 'completed' &&
                      file.files.Status &&
                      file.files.Status.toLowerCase() == 'completed'
                    "
                  ></i>
                  <i class="fas fa-times-circle" v-else></i>&nbsp;{{
                    file.files.Name
                  }}
                </div>
              </div>
            </template>

            <!-- Display MV Export notifications -->
            <template
              v-if="
                notification.type == 'O360EXPORT' ||
                notification.type == 'OsmoViewPDF' ||
                notification.type == 'OsmoViewCSV' ||
                notification.type == 'generic'
              "
            >
              <div class="notisubHeader">
                <strong>{{ notification.title }} </strong>
              </div>
              <div
                class="notiList"
                v-for="file in notification.files"
                :key="file.id"
              >
                <div
                  :title="file.message"
                  class="ps-2 pe-2"
                  :class="{
                    'success-message':
                      file.status == 'SUCCESS' && file.clickCallback,
                    'warning-message':
                      file.status == 'WARNING' && file.clickCallback,
                    'failure-message':
                      file.status == 'FAILURE' && file.clickCallback
                  }"
                  style="font-weight: 400"
                  @click="handleNotificationClick(file)"
                >
                  <i
                    class="fas fa-check-circle"
                    style="
                      color: #00cc2e;
                      font-size: 16px;
                      padding: 5px 5px 5px 0px;
                    "
                    v-if="file.status == 'SUCCESS'"
                  ></i>
                  <i
                    class="fas fa-exclamation-circle"
                    style="
                      color: rgb(253 191 91);
                      font-size: 16px;
                      padding: 5px 5px 5px 0px;
                    "
                    v-else-if="file.status == 'WARNING'"
                  ></i>
                  <i
                    class="fas fa-times-circle"
                    style="
                      color: #e94c39;
                      font-size: 16px;
                      padding: 5px 5px 5px 0px;
                    "
                    v-else-if="file.status == 'FAILURE'"
                  ></i
                  >&nbsp;{{ file.message }}
                </div>
              </div>
            </template>

            <!-- Display generic notifications -->
            <template v-else>
              <div class="notisubHeader">
                <strong>{{ notification.title }} </strong>
              </div>
              <div
                :title="notification.message"
                class="ps-2 pe-2"
                :class="{
                  'success-message':
                    notification.status == 'SUCCESS' &&
                    notification.clickCallback,
                  'warning-message':
                    notification.status == 'WARNING' &&
                    notification.clickCallback,
                  'failure-message':
                    notification.status == 'FAILURE' &&
                    notification.clickCallback
                }"
                style="font-weight: 400"
                @click="handleNotificationClick(notification)"
              >
                <i
                  class="fas fa-check-circle"
                  style="
                    color: #00cc2e;
                    font-size: 16px;
                    padding: 5px 5px 5px 0px;
                  "
                  v-if="notification.status == 'SUCCESS'"
                ></i>
                <i
                  class="fas fa-exclamation-circle"
                  style="
                    color: rgb(253 191 91);
                    font-size: 16px;
                    padding: 5px 5px 5px 0px;
                  "
                  v-else-if="notification.status == 'WARNING'"
                ></i>
                <i
                  class="fas fa-times-circle"
                  style="
                    color: #e94c39;
                    font-size: 16px;
                    padding: 5px 5px 5px 0px;
                  "
                  v-else-if="notification.status == 'FAILURE'"
                ></i
                >&nbsp;{{ notification.message }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- This modal is shown when the user click on a notification -->
    <b-modal
      id="projectChange"
      v-model="getProjectChangePopupStatus"
      no-close-on-backdrop
      no-close-on-esc
      centered
      scrollable
      size="lg"
      hide-footer
      hide-header
    >
      <div class="text-center colorOne font18px">
        <b> You are leaving your current screen.</b><br />
        Click “Cancel” to save your work and stay on the current page.
        <br />Click “OK” to continue.
      </div>
      <div class="text-center">
        <button
          type="button"
          class="btnRounded mt-3"
          @click="discardScreenChange()"
        >
          Cancel
        </button>
        &nbsp;
        <button
          type="button"
          class="btnRounded mt-3"
          @click="handleScreenChange()"
        >
          OK
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import JointUseGridAPI from "../dynamic/JointUse/LocalAPI/JointUseGridAPI";
export default {
  name: "notification",
  data() {
    return {
      toggleDropdown: false,
      currentNotificationObj: null
    };
  },
  created: function () {
    const self = this;
    window.addEventListener("click", function (e) {
      if (!self.$el.contains(e.target)) {
        self.toggleDropdown = false;
      }
    });
  },

  computed: {
    ...mapGetters(["getNotifications", "authToken"]),
    getProjectChangePopupStatus() {
      return this.$store.getters.getProjectChangePopupStatus;
    },

    notificationCount() {
      return this.$store.getters.getNotifications.flatMap(a => a.files).length;
    },
    getToken() {
      return this.$store.getters.authToken;
    }
  },
  methods: {
    activeComponunt(moduleName) {
      return () => import(`../dynamic/${this.entryPoint(moduleName)}`);
    },
    entryPoint(moduleName) {
      const appRegistery = this.$store.getters.appRegistryByName(
        moduleName,
        true
      );
      return appRegistery?.entryPoint;
    },
    onProjectChange() {
      this.$store.dispatch("closeMVPopup");
      this.$store.dispatch(
        "updateCurrentProjectById",
        this.currentNotificationObj.projectId
      );
      // log user changed project group
      this.$store.dispatch("logger", {
        LoggingCategory: "Information",
        Action: "Changed Project Group",
        Location: this.$router.currentRoute.value.name
      });
    },
    discardScreenChange() {
      this.$store.dispatch("showScreenChangeNotificationWarningModal", false);
    },
    async handleScreenChange() {
      console.log(this.$route.name, this.$router.currentRoute.value.name);
      await this.$store.dispatch(
        "showScreenChangeNotificationWarningModal",
        false
      );

      // if currently selected project is different than that of the notification switch projects
      this.currentNotificationObj = this.$store.getters.getCurrentNotification;

      console.log("current notification obj");
      console.log(this.currentNotificationObj);
      if (
        this.$store.getters.currentProjectId !=
        this.currentNotificationObj.projectId
      ) {
        this.onProjectChange();
      }

      // if user is outside the application that the notificaiton is for, switch application
      if (this.$route.name !== this.currentNotificationObj.notificationModule) {
        const targetComponentLowerCase =
          this.currentNotificationObj.notificationModule.toLowerCase();
        if (!this.$router.hasRoute(targetComponentLowerCase))
          this.$router.addRoute({
            path: `/${targetComponentLowerCase}`,
            name: targetComponentLowerCase,
            component: this.activeComponunt(
              this.currentNotificationObj.notificationModule
            )
          });
        await this.$router.push({
          name: targetComponentLowerCase
        });
      }

      await this.$nextTick(() => {
        this.currentNotificationObj.clickCallback();
      });
    },
    clearAllNotification() {
      // toggles notification dropdown list off
      this.toggleDropdown = false;

      // send read receipt for JU notifications
      JointUseGridAPI.sendReadReceiept(
        process.env.VUE_APP_JOINT_USE_SEND_READ_RECEIPT,
        this.authToken,
        null,
        this.$store.getters.currentProjectId
      );

      // send read receipt for o360 export notifications
      this.$store.dispatch("removeO360ExportNotifications");

      // send read receipt for JU import notifications
      this.$store.dispatch("removeJUImportNotifications");

      // clear notificaitons list
      this.$store.dispatch("setNotificationData", []);
    },
    handleNotificationClick(obj) {
      /* special handling for JU notification */
      if (obj.notificationModule == "jointuse") {
        // if (
        //   this.$route.name === obj.notificationModule &&
        //   this.$store.getters.currentProjectId == obj.projectId
        // ) {
        obj.clickCallback = () => {
          this.$store.commit("SET_JU_NOTIFICATIONS_STATUS", true);
        };
        // } else {
        //   obj.clickCallback = () => {
        //     this.$store.commit("SET_JUNOTIFICATION_FROM_OUTSIDE_JU", true);
        //   };
        // }
      }
      /* end of special handling for JU notification */

      // save this notification object for later use
      //this.$store.dispatch("setCurrentNotification", obj);
      this.$store.commit("SET_CURRENT_NOTIFICATION", obj);
      this.currentNotificationObj = obj;

      if (!obj.clickCallback) return;

      console.log("obj", obj.projectId, this.$store.getters.currentProjectId);
      this.toggleDropdown = false;

      const applicationSwitched = this.$route.name !== obj.notificationModule;
      const selectedProjectSwitched =
        this.$store.getters.currentProjectId !=
        this.currentNotificationObj.projectId;
      if (applicationSwitched || selectedProjectSwitched) {
        // show warning modal
        this.$store.dispatch("showScreenChangeNotificationWarningModal", true);
      } else {
        // open desired tool
        this.currentNotificationObj.clickCallback();
      }
    }
  }
};
</script>
<style scoped>
.cstDropdown {
  color: #000;
}
.notiList .ps-2.pe-2:hover {
  background: #d8f5d5;
  cursor: pointer;
}

.notiFailure:hover {
  background: #fbe7e4 !important;
}

.success-message:hover {
  background: #d8f5d5;
  cursor: pointer;
}
.warning-message:hover {
  background: #f1f5ba;
  cursor: pointer;
}
.failure-message:hover {
  background: #fbe7e4 !important;
}

.juNotification .dropdown-item {
  display: block;
  background: #fff;
  padding: 0.25rem 0.5rem;
  color: #1c3766 !important;
  border-bottom: 1px solid #f1f1f1;
  cursor: default;
}
.notiList .ps-2.pe-2 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
