<template>
  <b-dropdown-item href="#" v-on:click.prevent="navigate()">{{
    alias
  }}</b-dropdown-item>
</template>

<script>
import CardNavBase from "@/components/CardNavBase";
export default {
  extends: CardNavBase,
  props: ["targetComponunt", "alias", "entryPoint"]
};
</script>
