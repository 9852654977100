<template>
  <div class="nav-cont" :class="{ active: isActive }">
    <Component :is="navContent" :navigate="navigate"></Component>
    <!-- <button id="component-button" v-on:click="navigate()">{{targetComponunt}}</button> -->
  </div>
</template>

<script>
import CardNavBase from "./CardNavBase.vue";
import { defineAsyncComponent } from "vue";

export default {
  extends: CardNavBase,
  props: {
    targetComponunt: String,
    entryPoint: String,
    isActive: { default: false }
  },
  computed: {
    navContent() {
      return defineAsyncComponent(() =>
        import(`../components/dynamic/${this.entryPoint}Icon`)
      );
    }
  }
};
</script>
<style scoped>
.nav-cont {
  height: 70px;
  padding: 9px 25px;
  float: left;
}

.active {
  background-color: #7ca5d0;
  pointer-events: none;
}

@media only screen and (min-width: 1900px) {
  .nav-cont {
    height: 70px;
    padding: 9px 25px;
    float: left;
  }
}

@media only screen and (max-width: 1899px) and (min-width: 1676px) {
  .nav-cont {
    height: 70px;
    padding: 9px 19px;
    float: left;
  }
}

@media only screen and (max-width: 1675px) and (min-width: 1360px) {
  .nav-cont {
    height: 70px;
    padding: 9px 17px;
    float: left;
  }
}

@media only screen and (max-width: 1359px) and (min-width: 992px) {
  .nav-cont {
    height: 71px;
    padding: 9px 3px;
    float: left;
  }
}
@media only screen and (max-width: 991px) {
  .nav-cont {
    height: 48px;
    padding: 4px 20px;
    float: left;
  }
}
</style>
