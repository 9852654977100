<template>
  <div />
</template>

<script>
export default {
  props: ["targetComponunt", "entryPoint"],
  computed: {
    activeComponunt() {
      return () => import(`../components/dynamic/${this.entryPoint}`);
    }
  },
  methods: {
    navigate: function () {
      if (this.$store._actions["closeMVPopup"]) {
        this.$store.dispatch("closeMVPopup");
      }
      const targetComponentLowerCase = this.targetComponunt.toLowerCase();
      if (!this.$router.hasRoute(targetComponentLowerCase))
        this.$router.addRoute({
          path: `/${targetComponentLowerCase}`,
          name: targetComponentLowerCase,
          component: this.activeComponunt
        });

      this.$router.push({ name: targetComponentLowerCase });
    }
  }
};
</script>
<style></style>
