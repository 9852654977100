<template>
  <b-modal
    id="change-log-modal"
    hide-footer
    hide-header
    no-close-on-backdrop
    size="lg"
    centered
    :modelValue="showUserChangelog || reShowChanges"
  >
    <b-container>
      <!-- <i
        @click="closeChangeLog()"
        id="close-icon"
        class="fa fa-times"
        aria-hidden="true"
      ></i> -->
      <button
        type="button"
        @click="closeChangeLog()"
        aria-label="Close"
        class="btn-close"
      ></button>
      <b-row>
        <b-col id="main-column">
          <VueShowdown :markdown="markdownText"> </VueShowdown>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import Axios from "axios";
export default {
  data: function () {
    return {
      markdownText: ""
    };
  },
  computed: {
    showUserChangelog() {
      return this.$store.getters.showUserChangelog;
    },
    reShowChanges() {
      return this.$store.getters.reShowChangelog;
    }
  },
  methods: {
    closeChangeLog() {
      if (this.showUserChangelog) {
        this.updateHasViewed();
      }
      this.$store.dispatch("updateShowUserChangelog", false);
      this.$store.dispatch("updateReShowChangelog", false);
    },
    updateHasViewed() {
      const URL = process.env.VUE_APP_USER_VIEWED_CHANGES;
      const authToken = this.$store.getters.authToken;
      Axios.get(URL, {
        headers: { Authorization: `Bearer ${authToken}` }
      })
        .then(response => {})
        .catch(error => {
          console.log(error);
        });
    }
  },
  created() {
    const URL = `${process.env.VUE_APP_CHANGELOG_URL}?${Math.floor(
      100000 + Math.random() * 900000
    )}`;
    const authToken = this.$store.getters.authToken;
    Axios.get(URL)
      .then(response => {
        this.markdownText = response.data;
      })
      .catch(error => {
        console.log(error);
        const errorObj = {
          requestName: "Get O360 Change",
          Error: error,
          errorMessage: `An error was encountered displaying the latest Osmose 360 changes. If this error continues, please contact support.`
        };
        this.$store.dispatch("updateError", errorObj);
      });
  }
};
</script>

<style scoped>
/* #close-icon {
  display: flex;
  float: right;
  color: #707070;
}
#close-icon:hover {
  cursor: pointer;
  color: #1c3766;
} */
#main-column {
  margin-top: 5px;
  color: #1c3766;
}
</style>
