/**
 * Store to maintain itesm in the cart
 */

export default {
  state: {
    cartItems: [],
    cartVisible: false,
    zipCode: null,
    message: "",
    requestSubmitted: false,
    showLoader: false,
    currentPage: 0,
    requoted: false,
    actualCartItems: []
  },

  getters: {
    getCartItems: state => {
      return state.cartItems;
    },

    cartItemsCount: state => {
      if (
        !state.requoted &&
        state.cartItems != null &&
        state.cartItems != "" &&
        state.cartItems.length > 0
      ) {
        let totalCount = 0;
        state.cartItems.forEach(element => {
          if (element.products != null && element.products.length > 0) {
            element.products.forEach(item => {
              totalCount = totalCount + item.quantity;
            });
          }
        });
        return totalCount;
      } else if (
        state.requoted &&
        state.actualCartItems != null &&
        state.actualCartItems != "" &&
        state.actualCartItems.length > 0
      ) {
        let totalCount = 0;
        state.actualCartItems.forEach(element => {
          if (element.products != null && element.products.length > 0) {
            element.products.forEach(item => {
              totalCount = totalCount + item.quantity;
            });
          }
        });
        return totalCount;
      }

      return 0;
    },

    showCart: state => {
      return state.cartVisible;
    },

    getZipCode: state => {
      return state.zipCode;
    },

    getMessage: state => {
      return state.message;
    },

    isRequestSubmitted: state => {
      return state.requestSubmitted;
    },
    // showLoader: state => {
    //   return state.showLoader;
    // },
    getCurrentPage: state => {
      return state.currentPage;
    },

    isRequote: state => {
      return state.requoted;
    },

    getActualCartItems: state => {
      return state.actualCartItems;
    }
  },

  actions: {
    addItem({ commit }, data) {
      commit("ADD_ITEM", data);
    },

    setCartVisibility({ commit }, visible) {
      commit("SET_CART_VISIBILITY", visible);
    },

    removeProductGroup({ commit }, productGroupId) {
      commit("REMOVE_PRODUCT_GROUP", productGroupId);
    },

    removeProduct({ commit }, prodObj) {
      commit("REMOVE_PRODUCT", prodObj);
    },

    IncrementQuantity({ commit }, prod) {
      commit("INCREMENT_QUANTITY", prod);
    },

    DecrementQuantity({ commit }, prod) {
      commit("DECREMENT_QUANTITY", prod);
    },

    changeProduct({ commit }, newProd) {
      commit("CHANGE_PRODUCT", newProd);
    },
    updateQuantity({ commit }, updatedQty) {
      commit("UPDATE_QUANTITY", updatedQty);
    },

    setZipCode({ commit }, zipCode) {
      commit("SET_ZIPCODE", zipCode);
    },

    setMessage({ commit }, message) {
      commit("SET_MESSAGE", message);
    },

    changeSubmittedVisibility({ commit }, visibility) {
      commit("SET_SUBMIT_VISIBILITY", visibility);
    },

    setShowLoader({ commit }, showLoader) {
      commit("SET_SHOW_LOADER", showLoader);
    },

    resetCart({ commit }) {
      commit("RESET_CART");
    },

    updateCurrentCartPage({ commit }, pageNumber) {
      commit("UPDATE_CART_PAGE", pageNumber);
    },

    setRequotedItems({ commit }, requotedItems) {
      commit("SET_REQUOTED_ITEMS", requotedItems);
    },

    revertToActualItems({ commit }) {
      commit("REVERT_REQUOTED");
    }
  },

  mutations: {
    ADD_ITEM(state, data) {
      if (state.cartItems != null && state.cartItems.length > 0) {
        const item = state.cartItems.find(
          prod => prod.productGroupId === data.productGroupId
        );
        if (item != null) {
          const selectedProd = item.products.find(
            prod => prod.productId === data.products[0].productId
          );

          if (selectedProd != null) {
            selectedProd.quantity = parseInt(data.products[0].quantity);
          } else {
            const index = state.cartItems.findIndex(
              pGroup => pGroup.productGroupId === item.productGroupId
            );
            if (index != null && index != undefined && index > -1) {
              state.cartItems.splice(index, 1);
            }
            item.products.push(data.products[0]);
            state.cartItems.push(item);
          }
        } else {
          state.cartItems.push(data);
        }
      } else {
        state.cartItems.push(data);
      }
    },

    SET_CART_VISIBILITY: (state, visibility) => {
      state.cartVisible = visibility;
    },

    REMOVE_PRODUCT_GROUP: (state, pGroupId) => {
      if (state.cartItems != null && state.cartItems.length > 0) {
        const index = state.cartItems.findIndex(
          pGroup => pGroup.productGroupId === pGroupId
        );

        if (index != null && index != undefined && index > -1) {
          state.cartItems.splice(index, 1);
        }
      }
    },

    REMOVE_PRODUCT: (state, { gProductId, productId }) => {
      if (state.cartItems != null && state.cartItems.length > 0) {
        const item = state.cartItems.find(
          prod => prod.productGroupId === gProductId
        );

        if (item != null && item.products != null) {
          if (
            item.products.length == 1 &&
            item.products[0].productId === productId
          ) {
            const index = state.cartItems.findIndex(
              prod => prod.productGroupId === gProductId
            );
            if (index != null && index != undefined && index > -1) {
              state.cartItems.splice(index, 1);
            }
          } else if (item.products.length > 0) {
            const index = item.products.findIndex(
              prod => prod.productId === productId
            );
            if (index != null && index != undefined && index > -1) {
              item.products.splice(index, 1);
            }
          }
        }
      }
    },

    INCREMENT_QUANTITY: (state, { prodGroupId, prodId }) => {
      if (state.cartItems != null && state.cartItems.length > 0) {
        const item = state.cartItems.find(
          prd => prd.productGroupId === prodGroupId
        );
        if (item != null) {
          const selectedProd = item.products.find(
            prd => prd.productId === prodId
          );

          if (selectedProd != null && selectedProd.quantity < 999) {
            selectedProd.quantity = parseInt(selectedProd.quantity) + 1;
          }
        }
      }
    },

    DECREMENT_QUANTITY: (state, { prodGroupId, prodId }) => {
      if (state.cartItems != null && state.cartItems.length > 0) {
        const item = state.cartItems.find(
          prd => prd.productGroupId === prodGroupId
        );
        if (item != null) {
          const selectedProd = item.products.find(
            prd => prd.productId === prodId
          );

          if (selectedProd != null && selectedProd.quantity > 1) {
            selectedProd.quantity = selectedProd.quantity - 1;
          }
        }
      }
    },

    CHANGE_PRODUCT: (state, { prodGroupId, prodId, newProdCode }) => {
      if (state.cartItems != null && state.cartItems.length > 0) {
        const item = state.cartItems.find(
          prd => prd.productGroupId === prodGroupId
        );

        if (item != null) {
          const newProd = item.productOptions.find(
            prd => prd.productCode === newProdCode
          );

          const selectedIndex = item.products.findIndex(
            prd => prd.productId === prodId
          );

          if (selectedIndex > -1 && newProd != null) {
            item.products[selectedIndex].productId = newProd.productId;
            item.products[selectedIndex].productCode = newProd.productCode;
            item.products[selectedIndex].display = newProd.display;
          }
        }
      }
    },
    UPDATE_QUANTITY: (state, { prodGroupId, prodId, quantity }) => {
      if (state.cartItems != null && state.cartItems.length > 0) {
        const item = state.cartItems.find(
          prd => prd.productGroupId === prodGroupId
        );

        if (item != null) {
          const selectedIndex = item.products.findIndex(
            prd => prd.productId === prodId
          );

          if (selectedIndex > -1 && quantity > 0) {
            item.products[selectedIndex].quantity = quantity;
          }
        }
      }
    },

    SET_ZIPCODE: (state, zipCode) => {
      state.zipCode = zipCode;
    },

    SET_MESSAGE: (state, message) => {
      state.message = message;
    },

    SET_SUBMIT_VISIBILITY: (state, visibility) => {
      state.requestSubmitted = visibility;
    },

    SET_SHOW_LOADER: (state, showLoader) => {
      state.showLoader = showLoader;
    },

    RESET_CART: state => {
      state.cartItems = [];
      state.zipCode = null;
      state.message = "";
      state.uploads = [];

      if (state.requoted) {
        state.requoted = false;
        state.cartItems = state.actualCartItems;
        state.actualCartItems = [];
      }
    },

    UPDATE_CART_PAGE: (state, pageNumber) => {
      state.currentPage = pageNumber;
    },

    SET_REQUOTED_ITEMS: (state, requotedItems) => {
      if (!state.requoted) {
        state.cartVisible = true;
        state.currentPage = 0;
        state.requoted = true;
        state.actualCartItems = state.cartItems;
        state.cartItems = requotedItems;
      }
    },

    REVERT_REQUOTED: state => {
      if (state.requoted) {
        state.requoted = false;
        state.cartItems = state.actualCartItems;
        state.actualCartItems = [];
      }
    }
  }
};
