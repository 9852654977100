import { createApp, h } from "vue";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import VueTour from "vue3-tour";
//import VueSignalR from "@latelier/vue-signalr";
import { LicenseManager } from "ag-grid-enterprise";

// import "~@progress/kendo-theme-bootstrap/dist/all.scss";
// import "~bootstrap/scss/bootstrap.scss";
import "@progress/kendo-theme-bootstrap/dist/all.css";

// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/scss/bootstrap.scss";
import BootstrapVueNextPlugin from "bootstrap-vue-next";
// import { createBootstrap } from "bootstrap-vue-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "bootstrap";
import "vue-select/dist/vue-select.css";
import ToastPlugin from "vue-toast-notification";
// import "vue-toast-notification/dist/theme-bootstrap.css";
import "vue-toast-notification/dist/theme-default.css";
import { useVuelidate } from "@vuelidate/core";
import vSelect from "vue-select";
import UUID from "vue-uuid";
import { VueShowdownPlugin } from "vue-showdown";
import VueSvgGauge from "vue-svg-gauge";
import "vue3-tour/dist/vue3-tour.css";

import App from "./App.vue";
import router from "./router.js";
import store from "./store/store.js";
import SignupForm from "@/components/infrastructure/SignupForm.vue";
import Card from "@/components/Card.vue";
import NavIcon from "@/components/NavIcon.vue";
import Dashboard from "@/components/infrastructure/Dashboard.vue";
import ProjectSelection from "@/components/infrastructure/ProjectSelection.vue";
import Bottomheader from "@/components/infrastructure/Bottomheader.vue";
import TermsAndConditions from "@/components/infrastructure/TermsAndConditions.vue";
import Topheader from "@/components/infrastructure/Topheader.vue";
import O360Tour from "@/components/infrastructure/O360Tour.vue";
import O360Footer from "@/components/infrastructure/O360Footer.vue";
import O360Modal from "@/components/infrastructure/O360Modal.vue";
import O360Changelog from "@/components/infrastructure/O360Changelog.vue";
import O360ListFilter from "@/components/infrastructure/O360ListFilter.vue";
import NoticeScreen from "@/components/infrastructure/NoticeScreen.vue";
import { createPinia } from "pinia";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

// import { configureCompat } from "vue";
import { useBootstrapVueStore } from "@/store/Modules/BootstrapVueStore";

const pinia = createPinia();

// configureCompat({
//   MODE: 3
// required by Vue-router
// CONFIG_OPTION_MERGE_STRATS: "suppress-warning",
// GLOBAL_PRIVATE_UTIL: "suppress-warning",
// GLOBAL_PROTOTYPE: "suppress-warning",

// // required due to global mixin on vue-router
// INSTANCE_EVENT_HOOKS: "suppress-warning",
// OPTIONS_DESTROYED: "suppress-warning",
// INSTANCE_EVENT_EMITTER: "suppress-warning",

// // required by portal-vue
// GLOBAL_SET: "suppress-warning",

// // globals
// GLOBAL_EXTEND: "suppress-warning",
// GLOBAL_MOUNT: "suppress-warning",

// // functional does not work how you think ;)
// COMPONENT_FUNCTIONAL: "suppress-warning",

// COMPONENT_ASYNC: "suppress-warning",
// CUSTOM_DIR: "suppress-warning",
// OPTIONS_DATA_MERGE: "suppress-warning",
// RENDER_FUNCTION: "suppress-warning",
// COMPONENT_V_MODEL: false,
// INSTANCE_LISTENERS: false
// });
/**************************End ************************************/

if (process.env.VUE_APP_AG_GRID_LICENSE)
  LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_LICENSE);
const app = createApp(App);
app.use(pinia);
app.config.globalProperties.$bvModal = useBootstrapVueStore();
app.use(Viewer);
// app.config.productionTip = false;
app.use(ToastPlugin);
// app.use(useVuelidate);
app.component("VSelect", vSelect);
app.use(UUID);
app.use(VueTour);
app.use(VueShowdownPlugin, { options: { openLinksInNewWindow: true } });
app.use(VueSvgGauge);
app.component("SignupForm", SignupForm);
app.component("Dashboard", Dashboard);
app.component("Bottomheader", Bottomheader);
app.component("Topheader", Topheader);
app.component("O360Tour", O360Tour);
app.component("O360Footer", O360Footer);
app.component("Card", Card);
app.component("NavIcon", NavIcon);
app.component("NoticeScreen", NoticeScreen);
app.component("O360Modal", O360Modal);
app.component("O360ListFilter", O360ListFilter);
app.component("TermsAndConditions", TermsAndConditions);
app.component("ProjectSelection", ProjectSelection);
app.component("O360Changelog", O360Changelog);
const localURL =
  "https://l20q2110.local.osmose.com/O360NotificationService/NotificationHub";
// app.use(VueSignalR, localURL);
//app.use(VueSignalR, process.env.VUE_APP_NOTIFICATION_HUB);
app.use(router);
app.use(store);
//app.use(createBootstrap());
app.use(BootstrapVueNextPlugin({ components: true, directives: true }));
app.mount("#app");
// app.mount("#app");
export default app;
